<script>
import PushNotificationsConfigure from './pushNotifications/PushNotificationsConfigure'
import NotificationSwitches from '@/features/users/components/NotificationSwitches'
import store from '@/infrastructure/store'

export default {
  components: {
    NotificationSwitches,
    PushNotificationsConfigure,
  },

  data() {
    return {
      groupSwitchMappings: {
        inApp: {
          loadActionFactory: () => store.dispatch('notifications/getInAppPreferences'),
          saveActionFactory: ({ switches }) => store.dispatch('notifications/saveInAppPreferences', { switches: switches }),
        },
        email: {
          loadActionFactory: () => store.dispatch('notifications/getEmailPreferences'),
          saveActionFactory: ({ switches }) => store.dispatch('notifications/saveEmailPreferences', { switches: switches }),
        },
        push: {
          loadActionFactory: () => store.dispatch('notifications/getPushPreferences'),
          saveActionFactory: ({ switches }) => store.dispatch('notifications/savePushPreferences', { switches: switches }),
        },
      },

      groupsChangedCounter: 0,
    }
  },

  computed: {
    hasPushNotificationSubscription: () => store.getters['userProfile/hasPushNotificationSubscription'],

    enabledGroupKeys() {
      return Object.keys(this.groupSwitchMappings).filter((groupKey) => groupKey !== 'push' || this.hasPushNotificationSubscription)
    },
  },

  watch: {
    hasPushNotificationSubscription() {
      this.groupsChangedCounter++
    },
  },

  methods: {
    async loadGroupedSwitches() {
      const enabledGroupKeys = this.enabledGroupKeys

      const allSwitches = await Promise.all(enabledGroupKeys.map((groupKey) => this.groupSwitchMappings[groupKey].loadActionFactory()))

      const groupedSwitches = {}
      enabledGroupKeys.forEach((groupKey, idx) => {
        groupedSwitches[groupKey] = allSwitches[idx]
      })

      return groupedSwitches
    },

    async saveSwitchesSingleGroup(groupKey, newGroupSwitches) {
      const saveActionFactory = this.groupSwitchMappings[groupKey].saveActionFactory
      await saveActionFactory({ switches: newGroupSwitches })
    },
  },
}
</script>

<template>
  <div>
    <NotificationSwitches
      :load-grouped-switches="loadGroupedSwitches"
      :save-switches-single-group="saveSwitchesSingleGroup"
      :changed-counter="groupsChangedCounter"
    />

    <div class="pt-5"></div>

    <PushNotificationsConfigure />
  </div>
</template>
