<script>
export default {
  props: {
    saveFunction: { type: Function, required: true },
    initialSwitchValue: { type: Boolean },
  },

  data() {
    return {
      switchValue: this.initialSwitchValue,
      busySaving: false,
    }
  },

  methods: {
    async onSwitchChanged($event) {
      this.busySaving = true
      try {
        await this.saveFunction($event)
      } finally {
        this.busySaving = false
      }
    },
  },
}
</script>

<template>
  <v-switch v-model="switchValue" :disabled="busySaving" :loading="busySaving" hide-details class="mt-0 mb-0" @change="onSwitchChanged" />
</template>
