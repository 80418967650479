<script>
import usePushNotifications from '@/infrastructure/pushNotifications/pushNotificationsComposable'
import store from '@/infrastructure/store'

export default {
  setup() {
    const { hasCapability, isInitializing, initIsTakingLong, failedPermissionResult, initialize, subscribeDevice, unsubscribeDevice } = usePushNotifications()

    return {
      hasCapability,
      isInitializing,
      initIsTakingLong,
      failedPermissionResult,

      initialize,
      subscribeDevice,
      unsubscribeDevice,
    }
  },

  data() {
    return {
      isEnabled: false,
      busySavingApi: false,
    }
  },

  computed: {
    hasPushNotificationSubscription: () => store.getters['userProfile/hasPushNotificationSubscription'],
  },

  watch: {
    failedPermissionResult(to) {
      if (to) {
        setTimeout(() => {
          this.isEnabled = false
        }, 100)
      }
    },
  },

  async mounted() {
    await this.initialize()
    if (this.hasPushNotificationSubscription) {
      this.isEnabled = true
    }
  },

  methods: {
    onIsEnabledChange(value) {
      if (value) {
        this.enableSubscription()
      } else {
        this.disableSubscription()
      }
    },

    async enableSubscription() {
      this.busySavingApi = true
      try {
        await this.subscribeDevice()
      } finally {
        this.busySavingApi = false
      }
    },

    async disableSubscription() {
      this.busySavingApi = true
      try {
        await this.unsubscribeDevice()
      } finally {
        this.busySavingApi = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-text>
        <v-switch
          v-model="isEnabled"
          label="Send push notifications to this device"
          :loading="busySavingApi || isInitializing"
          :disabled="!hasCapability || isInitializing || busySavingApi"
          hide-details
          color="success"
          class="mt-0"
          @change="onIsEnabledChange($event)"
        />

        <v-expand-transition>
          <div v-if="!hasCapability" class="mt-8">
            <v-alert type="warning" :value="true">
              <div>Your browser does not support push notifications</div>
            </v-alert>
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="isInitializing && initIsTakingLong" class="mt-8">
            <v-alert type="warning" :value="true">
              <div>We are having trouble checking push notification capability, it is possible that your browser does not support push notifications</div>
            </v-alert>
          </div>
        </v-expand-transition>

        <v-expand-transition>
          <div v-if="failedPermissionResult" class="mt-8">
            <v-alert type="warning" :value="true">
              Push notification permission has been {{ failedPermissionResult }}, please check your browser settings
            </v-alert>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </div>
</template>
