import { ref } from '@vue/composition-api'
import config from '@/config'
import { createApiInstance } from '@/infrastructure/apis/apiInstance'
import store from '@/infrastructure/store'

export default function usePushNotifications() {
  const hasCapability = 'Notification' in window && 'serviceWorker' in navigator
  const isInitializing = ref(false)
  const initIsTakingLong = ref(false)
  const failedPermissionResult = ref(undefined)

  let registeredServiceWorker

  const initialize = async () => {
    if (!hasCapability) return

    isInitializing.value = true
    initIsTakingLong.value = false
    store.commit('userProfile/setPushNotificationSubscription', { subscription: null })

    setTimeout(() => {
      initIsTakingLong.value = true
    }, 5000)

    registeredServiceWorker = await navigator.serviceWorker.ready

    const subscription = await registeredServiceWorker.pushManager.getSubscription()
    store.commit('userProfile/setPushNotificationSubscription', { subscription: subscription })

    isInitializing.value = false
    initIsTakingLong.value = false
  }

  const subscribeDevice = async () => {
    if (!hasCapability) {
      console.error('Cannot call push notification subscribeDevice without hasCapability being true')
      alert('Technical error: cannot call push notification subscribeDevice without hasCapability being true')
      return
    }

    failedPermissionResult.value = undefined

    let permissionResult = await Notification.requestPermission()
    if (permissionResult !== 'granted') {
      console.warn(`Failed to get notification permissions, result: '${permissionResult}'`)
      failedPermissionResult.value = permissionResult
      return
    }

    if (!registeredServiceWorker) {
      console.error('Cannot call push notification subscribeDevice with a registered service worker')
      alert('Technical error: cannot call push notification subscribeDevice with a registered service worker')
      return
    }

    function urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
      const rawData = window.atob(base64)
      let outputArray = new Uint8Array(rawData.length)
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    }

    const convertedVapidPublicKey = urlBase64ToUint8Array(config.Vapid.PublicKey)
    const subscription = await registeredServiceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidPublicKey,
    })
    store.commit('userProfile/setPushNotificationSubscription', { subscription: subscription })

    console.log('subscription created on the client', subscription)

    await createApiInstance().post('/UserDeviceNotifications/subscription', { subscription: subscription })
  }

  const unsubscribeDevice = async () => {
    const subscription = store.state.userProfile.pushNotificationSubscription
    if (!subscription) return

    await subscription.unsubscribe()

    await createApiInstance().delete('/UserDeviceNotifications/subscription', { data: { endpoint: subscription.endpoint } })
  }

  return {
    hasCapability,
    isInitializing,
    initIsTakingLong,
    failedPermissionResult,

    initialize,
    subscribeDevice,
    unsubscribeDevice,
  }
}
