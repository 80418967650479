<script>
import NotificationSwitch from './NotificationSwitch'

export default {
  components: {
    NotificationSwitch,
  },

  props: {
    loadGroupedSwitches: { type: Function, required: true },
    saveSwitchesSingleGroup: { type: Function, required: true },
    changedCounter: { type: Number, required: true },
  },

  data() {
    return {
      groupedSwitches: {},
      busyLoading: false,
    }
  },

  computed: {
    groupNames() {
      return Object.keys(this.groupedSwitches)
    },

    distinctSwitchLabels() {
      const distinctLabels = {}
      Object.values(this.groupedSwitches).forEach((switchesInGroup) => {
        Object.keys(switchesInGroup).forEach((label) => {
          distinctLabels[label] = true
        })
      })

      return Object.keys(distinctLabels)
    },

    switchesTable() {
      return this.distinctSwitchLabels.map((label) => ({
        switchKey: label,
        title: label,
        columns: this.groupNames.map((group) => {
          const switchValue = this.groupedSwitches[group][label]
          return {
            groupKey: group,
            hasSwitch: switchValue !== undefined && switchValue !== null,
            switchValue: switchValue,
          }
        }),
      }))
    },
  },

  watch: {
    changedCounter() {
      this.reloadSwitchesData()
    },
  },

  mounted() {
    this.reloadSwitchesData()
  },

  methods: {
    toSentenceCase(str) {
      if (!str) return str
      return str.substr(0, 1).toUpperCase() + str.toLowerCase().substr(1)
    },

    async reloadSwitchesData() {
      this.busyLoading = true
      try {
        this.groupedSwitches = (await this.loadGroupedSwitches()) || {}
      } finally {
        this.busyLoading = false
      }
    },

    async saveNewSwitchValue({ switchKey, groupKey, newValue }) {
      this.groupedSwitches[groupKey][switchKey] = !!newValue
      await this.saveSwitchesSingleGroup(groupKey, this.groupedSwitches[groupKey])
    },
  },
}
</script>

<template>
  <v-card :loading="busyLoading">
    <v-simple-table>
      <thead>
        <tr>
          <td>Type</td>
          <td v-for="groupName in groupNames" :key="groupName">
            {{ toSentenceCase(groupName.replace(/([A-Z])/g, ' $1')) }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in switchesTable" :key="row.switchKey">
          <td>{{ toSentenceCase(row.title.replace(/([A-Z])/g, ' $1')) }}</td>
          <td v-for="col in row.columns" :key="col.groupKey">
            <NotificationSwitch
              v-if="col.hasSwitch"
              :initial-switch-value="col.switchValue"
              :save-function="(newValue) => saveNewSwitchValue({ switchKey: row.switchKey, groupKey: col.groupKey, newValue: newValue })"
            />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>
