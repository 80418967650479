<script>
import NotificationsConfigure from '@/features/users/components/NotificationsConfigure'

export default {
  components: {
    NotificationsConfigure,
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-title> Notification preferences</v-card-title>

      <v-card-text>
        <NotificationsConfigure />
      </v-card-text>
    </v-card>
  </div>
</template>
